'use client'
import styled from 'styled-components'
import { mq, getP160, getP40, createUnderline } from '@/styles'
import dynamic from 'next/dynamic'
import { useTranslator, useSession, useDeviceType } from '@/hooks'
import { Link } from '../Link'
import { Dropdown } from '../dropdown'
import { LOCALE, stringToUppercase, getActualPath } from '@/utils'
import { useMemo } from 'react'
import { usePathname, useRouter, useParams } from 'next/navigation'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

const  videoSrc = {
  desktop: {
    src: 'https://vimeo.com/917033316',
  },
  mobile:{
    src: 'https://vimeo.com/917038965'
  }

}

const getSubjects = (data, lang) => {
  if(Array.isArray(data)) {
    return data.map((item, idx) => {
      return (
        <li key={`${idx}-${item.value}`}>
          {item.name === 'Instagram' ? <a href={item.link} target='_blank'>{item.name}</a>
            :
            <Link href={`${lang}/${item.link}`}>{item.name}</Link>
          }
        </li>
      )
    })
  } else return []
}


export const getArrayOptions = (pathname, actualLang) => {
  return [
    {value: 'EN', path: getActualPath(pathname, actualLang, 'en')},
    {value: 'ES', path: getActualPath(pathname, actualLang, 'es')},
    {value: 'FR', path: getActualPath(pathname, actualLang, 'fr')},
    {value: 'IT', path: getActualPath(pathname, actualLang, 'it') },
    {value: 'DE', path: getActualPath(pathname, actualLang, 'de')},
    {value: 'PT', path: getActualPath(pathname, actualLang, 'pt')},
  ]
}


const getVisibility = (pathname) => {
  if(pathname.includes('login')) return false
  else if(pathname.includes('signup')) return false
  else if(pathname.includes('password')) return false
  else return true
}

export const Footer = ({  }) => {
  const { t } = useTranslator()
  const { user } = useSession()
  const { lang } = useParams()
  const FooterPages = useMemo(() => getSubjects(user ? t('layout.footer.nav.private') : t('layout.footer.nav.public'), lang ?? 'en'), [lang, t, user])
  const pathname = usePathname()
  const { size } = useDeviceType()
  const srcVideo = useMemo(() => size.width <= 900 ? videoSrc.mobile.src : videoSrc.desktop.src, [size])
  const shouldDisplay = useMemo(() => getVisibility(pathname), [pathname])
  const { push } = useRouter()
  return shouldDisplay && (
    <FooterStyled>
      <Overlay>
        <hgroup>
          <h1 onClick={() => { push(`${!lang ? '/en' : `/${lang}`}${user ? '/projects' : '/signup'}`)}} >{t('layout.footer.try')}</h1>
          <p dangerouslySetInnerHTML={{ __html: t('layout.footer.try_small')}} />
        </hgroup>
        <nav className='footer_nav'>
          <ul>
            {FooterPages}
            <Dropdown className='detail-dropdown' trigger={stringToUppercase(lang ? lang: LOCALE)} options={getArrayOptions(pathname, lang)}  direction='up'/>
          </ul>
        </nav>
      </Overlay>
      <ReactPlayer key='p-land' className='player-landscape' url={srcVideo} width={'100%'} height={'100%'} controls={false} volume={0} muted={true} loop={true} playsinline={true} playing={true} />
    </FooterStyled>
  )
}


const FooterStyled = styled.footer`
  align-items: center;
  display: flex;
  height:100vh;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${mq.greaterThan('desktop')} {
    gap: 10px;
  }

  > div:last-child {
    height:100vh !important;
    z-index: 0;

    &[data-vimeo-initialized='true'] {
      height: 100%!important;
      width: 100%!important;
    }
  }

  iframe {
    aspect-ratio:393/740;
    bottom: 0;
    height: auto !important;
    position: absolute;
    width: 100vw !important;

    @media screen and (min-width:901px) {
      aspect-ratio: 36 / 25;
      height: auto !important;
      left: unset;
      transform: none;
      width: 100% !important;
    }

    @media screen and (min-width:1550px) {
      position: unset;
    }
  }

  .player-landscape {
    z-index: 0;
  }
`

const Overlay = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100vh;
  position: absolute;
  width: 100%;
  z-index: 1;


  hgroup {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 45%;
    transform: translateY(-45%);
    width: 100%;

    ${mq.greaterThan('tablet')} {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  h1 {
    ${getP160()}
    cursor: pointer;
    margin-bottom: 15px;
    max-width: 90vw;
    pointer-events:auto;
    width: 100%;
  }

  p {
    ${getP40()}
    text-align: center;
    padding: 0 16px;

    a {
      ${createUnderline({ color: 'white', reverse: true })}
    }
  }

  .footer_nav  {
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    > ul {
      align-items: flex-start;
      align-content: space-between;
      bottom: 30px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 30px;
      height: 140px;
      position: absolute;
      width: 250px;

      ${mq.greaterThan('tablet')} {
        align-items: center;
        bottom: 50px;
        display: flex;
        flex-direction: row;
        gap: 30px;
        height: unset;
        justify-content: center;
        position: absolute;
        width: 100%;
      }

      >li {
        font-size: 14px;
      }
    }
  }
`
